/** @format */

import {
  IssuesCloseOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Cascader,
  Col,
  Collapse,
  DatePicker,
  Row,
  Segmented,
  Skeleton,
  Tooltip,
} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import React, { Ref, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../constants/api";
import { TUser } from "../../../redux-modules/declarations/maps/auth";
import { TGroup } from "../../../redux-modules/declarations/maps/groups";
import { TCustomPresence } from "../../../redux-modules/declarations/maps/presences";
import { usePresences } from "../../../redux-modules/hooks/usePresences";
import {
  selectorIsGestioneTimebankEnabled,
  selectorLoggedUser,
} from "../../../redux-modules/selectors/selectorsAuth";
import { selectorGroupsList } from "../../../redux-modules/selectors/selectorsGroups";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { selectorCustomStyleOptions } from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor, Colors } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { utilityDecimalHours } from "../../../utils/format/decimalHours";
import { utilityGetDatesSiblingsToOneDate } from "../../../utils/format/rangeBetweenTwoDates";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { hexToRgb } from "../../../utils/styles";
import { UICard } from "../../ui/Card";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";
import { TPresenceCreateObject } from "../SideDialogs/AddPresence";
import { ShowPresenceDrawer } from "../SideDialogs/ShowPresence";
import { EditPresenceCalendar } from "./EditMode";
import { IndexDay } from "./partials/IndexDay";
import { useRequests } from "../../../redux-modules/hooks/useRequests";
import { TRequest } from "../../../redux-modules/declarations/maps/requests";
import { RequestDetailDrawer } from "../RequestCalendar/partials/RequestDetailDrawer";
const { Panel } = Collapse;

interface Props {
  setInitialValues: (obj: TPresenceCreateObject) => void;
  needToUpdateAbsences: boolean;
  needToUpdateAll: boolean;
  onChangeCurrentDate: (date: moment.Moment) => void;
}
export const PresenceCalendar = ({
  setInitialValues,
  needToUpdateAbsences,
  needToUpdateAll,
  onChangeCurrentDate,
}: Props): JSX.Element => {
  const [selectedMode, setSelectedMode] = useState<"Visualizza" | "Modifica">("Visualizza");
  const [showModal, setShowModal] = useState(false);
  const [openedGroups, setOpenedGroups] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [activeDay, setActiveDay] = useState(moment());
  const rowRef: Ref<HTMLDivElement> | undefined = useRef(null);
  const [datesRange, setDatesRange] = useState(utilityGetDatesSiblingsToOneDate(moment().toDate()));
  const loggedUser = useSelector(selectorLoggedUser);
  const timesRange = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  const [activePresence, setActivePresence] = useState<TCustomPresence | null>(null);
  const [filteredGroups, setFilteredGroups] = useState<string[]>([
    ...(loggedUser?.groups?.map(e => e.id) || []),
  ]);
  const permissionList = useSelector(selectorPermissionList);
  const canUpdatePresences = hasPermission(permissionList, "Presence", "Update");
  const isGestioneTimebankAbilitata = useSelector(selectorIsGestioneTimebankEnabled);
  const customStyleOptions = useSelector(selectorCustomStyleOptions);

  const [userListComplete, setUserListComplete] = useState<
    { groups: TGroup[]; name: string; surname: string; id: string }[]
  >([]);

  const {
    presenceList,
    isPresenceListLoading,
    presencesOwnersUnique,
    updatePresenceList,
    updateAbsenceList,
    absenceList,
    absenceOwnerUniqueList,
    isAbsenceListLoading
  } = usePresences(moment(activeDay).format("YYYY-MM-DD"));

  const {requestList, updateRequestList} = useRequests(datesRange, false);
  const [activeRequest, setActiveRequest] = useState<TRequest | null>(null);

  useEffect(() => {
    setUserListComplete(
      [
        ...presencesOwnersUnique.map(e => ({
          groups: e.original.user.groups,
          name: e.original.user.name,
          surname: e.original.user.surname,
          id: e.original.user.id,
        })),
        ...absenceOwnerUniqueList.map(e => ({
          groups: e.user.groups,
          name: e.user.name,
          surname: e.user.surname,
          id: e.user.id,
        })),
      ].filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i),
    );
  }, [presencesOwnersUnique, absenceOwnerUniqueList]);

  useEffect(() => {
    console.log(filteredGroups);
  }, [filteredGroups]);

  const onChangeFilters = (value: string[][]) => {
    const groupsArray = value.filter(e => e[0] === "groups");

    let selectedGroups: string[] = [];
    let selectedUsers: string[] = [];

    if (groupsArray.length > 0) {
      if (groupsArray.length == 1) {
        if (!!groupsArray[0][1]) {
          selectedGroups = [groupsArray[0][1]];
          if (!!groupsArray[0][2]) {
            selectedUsers = [groupsArray[0][2]];
          }
        }
      } else {
        selectedGroups = groupsArray.map(e => e[1]);
        selectedUsers = groupsArray.filter(e => e.length > 2).map(e => e[2]);
      }
    }

    setFilteredGroups(selectedGroups);
  };

  const groups = useSelector(selectorGroupsList);

  const goBack = () => setActiveDay(moment(activeDay).subtract(1, "days"));
  const goNext = () => setActiveDay(moment(activeDay).add(1, "days"));
  const goToday = () => setActiveDay(moment());
  //@ts-ignore
  const goTo = date => setActiveDay(date || moment());
  const [firstTimeLanded, setFirstTimeLanded] = useState(true);

  useEffect(() => {
    setDatesRange(utilityGetDatesSiblingsToOneDate(activeDay.toDate()));
    onChangeCurrentDate(activeDay);

    if(firstTimeLanded){
      setFirstTimeLanded(false)
    }else{
      updatePresenceList(moment(activeDay).format("YYYY-MM-DD"));
    }
  }, [activeDay]);

  useEffect(() => {
    if (needToUpdateAbsences) {
      updateAbsenceList();
    }
  }, [needToUpdateAbsences]);

  useEffect(() => {
    if (needToUpdateAll) {
      updateAbsenceList();
      checkForUpdateRequestList();
      updatePresenceList(moment(activeDay).format("YYYY-MM-DD"));
    }
  }, [needToUpdateAll]);

  const checkForUpdateRequestList = () => updateRequestList(datesRange);

  const handleOpenModalRequest = (id: string) => {
    console.log(id);
    const request = requestList.find(el => el.id === id);
    if (request) {
      setShowModal(true);
      setModalLoading(true);
      apiClient
        .get(API_ENDPOINTS.REQUESTS + `/${request.id}`)
        .then(res => {
          setActiveRequest(res.data.data);
          setModalLoading(false);
        })
        .catch(err => {});
    }
  };

  const handleCloseModalRequest = (refresh: boolean = false) => {
    setShowModal(false);
    setActiveRequest(null);
  };

  const handleOpenModal = (presence_id: string, user: string) => {
    //const presence = getPresenceByIndex(index, user);
    if (presence_id) {
      setShowModal(true);
      setModalLoading(true);
      apiClient
        .get(API_ENDPOINTS.PRESENCES + `/${presence_id}`)
        .then(res => {
          setActivePresence(res.data.data);
          setModalLoading(false);
        })
        .catch(err => {});
    }
  };

  const proportionTwentyfourToHundred = (hour: string) => {
    const hourProportion = parseFloat(hour) / 24;
    return (hourProportion * 100).toFixed(2);
  };

  const getAbsenceColsByUserId = (userId: string) => {
    const todaysAbsences = absenceList.filter(
      absence => absence.user.id === userId && !absence.is_partial_presence,
    );

    return (
      <>
        {todaysAbsences.map(absence => (
          <div
            key={absence.datetime_from + absence.datetime_to + absence.user.id}
            //onClick={() => handleOpenModal(presence.original.id, userId)}
            onClick={() => absence.id ? handleOpenModalRequest(absence.id) : () => {}}
            style={{
              position: "absolute",
              cursor: absence.id ? "pointer" : undefined,
              backgroundColor: absence.color + "cc",
              left: proportionTwentyfourToHundred(absence.centesimal_datetime_from) + "%",
              width: proportionTwentyfourToHundred(absence.centesimal_duration) + "%",
              //@ts-ignore
              zIndex: !!absence.size_unit ? (absence.size_unit == "GIORNI" ? 990 : 994) : 992,
              display: "flex",
              justifyContent: "space-between",
              padding: "1px 5px",
              fontWeight: 500,
              marginTop: "9px",
              borderRadius: "7px",
              color: "white",
              border: "1px solid " + absence.color,
              textShadow: "0px 0px 2px black",
            }}
          >
            <span style={{ marginRight: "5px", whiteSpace: "nowrap" }}>{absence.description}</span>
          </div>
        ))}
      </>
    );
  };
  const getPresenceColsByUserId = (userId: string): JSX.Element => {
    const todaysPresences = presenceList.filter(el => el.original.user_id === userId);
    const todaysAbsences = absenceList.filter(
      el => el.user.id === userId && el.is_partial_presence,
    );

    return (
      <>
        {todaysAbsences.map(absence => (
          <div
            key={absence.datetime_from + absence.datetime_to + absence.user.id}
            //onClick={() => handleOpenModal(presence.original.id, userId)}
            style={{
              position: "absolute",
              cursor: "pointer",
              backgroundColor: absence.color + "cc",
              left: proportionTwentyfourToHundred(absence.centesimal_datetime_from) + "%",
              width: proportionTwentyfourToHundred(absence.centesimal_duration) + "%",
              //@ts-ignore
              zIndex: !!absence.size_unit ? (absence.size_unit == "GIORNI" ? 990 : 991) : 992,
              display: "flex",
              justifyContent: "space-between",
              padding: "1px 5px",
              fontWeight: 500,
              marginTop: "9px",
              borderRadius: "7px",
              color: "white",
              border: "1px solid " + absence.color,
              textShadow: "0px 0px 2px black",
            }}
          >
            <span style={{ marginRight: "5px" }}>{absence.description}</span>
          </div>
        ))}
        {todaysPresences.map(presence => (
          <div
            key={presence.original.id}
            onClick={() => handleOpenModal(presence.original.id, userId)}
            style={{
              position: "absolute",
              cursor: "pointer",
              backgroundColor: presence?.original.presence_type_color + "cc",
              left:
                proportionTwentyfourToHundred(
                  presence.modified
                    ? presence.modified.ui_centesimal_datetime_from
                    : presence.original.ui_centesimal_datetime_from,
                ) + "%",
              width:
                proportionTwentyfourToHundred(
                  presence.modified && parseInt(presence.modified.ui_centesimal_duration) <= 24
                    ? presence.modified.ui_centesimal_duration
                    : presence.original.ui_centesimal_duration,
                ) + "%",
              zIndex: 993,
              display: "flex",
              justifyContent: "space-between",
              padding: "1px 5px",
              fontWeight: 500,
              marginTop: "9px",
              borderRadius: "7px",
              color: "white",
              border: "1px solid " + presence?.original.presence_type_color,
              textShadow: "0px 0px 2px black",
            }}
          >
            <span
              style={{
                marginRight: "5px",
                textShadow:
                  customStyleOptions?.showHandonLabelInPresenceList &&
                  !!presence.modified &&
                  presence.modified.presence_datetime_from != presence.original.datetime_from
                    ? "2px 0 red, -2px 0 red, 0 2px red, 0 -2px red, 1px 1px red, -1px -1px red, 1px -1px red, -1px 1px red"
                    : "0px 0px 2px black",
              }}
            >
              {moment(
                presence.modified
                  ? presence.modified.presence_datetime_from
                  : presence.original.datetime_from,
                "DD/MM/YYYY HH:mm:ss",
              ).format("HH:mm")}
            </span>
            {customStyleOptions?.showOfficeLabelInPresenceList && (
              <span
                style={{
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  marginTop: "2px",
                }}
              >
                {presence.original.office?.name}
              </span>
            )}
            <span
              style={{
                textShadow:
                  customStyleOptions?.showHandonLabelInPresenceList &&
                  !!presence.modified &&
                  !!presence.original.datetime_to &&
                  presence.modified.presence_datetime_to != presence.original.datetime_to
                    ? "2px 0 red, -2px 0 red, 0 2px red, 0 -2px red, 1px 1px red, -1px -1px red, 1px -1px red, -1px 1px red"
                    : "0px 0px 2px black",
              }}
            >
              {presence.original.datetime_to ? (
                moment(
                  presence.modified
                    ? presence.modified.presence_datetime_to
                    : presence.original.datetime_to,
                  "DD/MM/YYYY HH:mm:ss",
                ).format("HH:mm")
              ) : (
                <SyncOutlined spin style={{ filter: "drop-shadow(black 0px 0px 2px)" }} />
              )}
            </span>
          </div>
        ))}
      </>
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActivePresence(null);
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = current => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const filters = [
    {
      label: "Gruppi",
      value: "groups",
      children: groups.data.map(group => ({
        label: group.name,
        value: group.id,
        children: isPresenceListLoading
          ? []
          : userListComplete
              .filter(user => user.groups.map(g => g.id).includes(group.id))
              .map(user => ({
                label: user.surname + " " + user.name,
                value: user.id,
              })),
      })),
    },
  ];

  return (
    <>
      {canUpdatePresences && (
        <Row style={{ marginBottom: "15px" }} gutter={[16, 16]}>
          <Col>
            <Segmented
              options={["Visualizza", "Modifica"]}
              //@ts-ignore
              onChange={value => setSelectedMode(value)}
            />
          </Col>
          <Col span={8} style={{ display: "inline-flex", textAlign: "center" }}>
            {isPresenceListLoading ? (
              <span style={{ height: "20px" }}>
                <Skeleton.Input active style={{ marginRight: "20px", paddingRight: "30px" }} />
              </span>
            ) : (
              <CustomCascader
                style={{ width: "100%" }}
                showSearch
                options={filters}
                loading={isPresenceListLoading}
                defaultValue={
                  filteredGroups.length ? [...filteredGroups.map(e => ["groups", e])] : []
                }
                //@ts-ignore
                onChange={onChangeFilters}
                multiple
                maxTagCount="responsive"
                placeholder={
                  <span>
                    <SearchOutlined style={{ marginRight: "5px" }} /> Filtra per gruppi
                  </span>
                }
              />
            )}
          </Col>
        </Row>
      )}
      {selectedMode === "Visualizza" && (
        <>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={6}></Col>
                <Col span={16} style={{ textAlign: "center" }}>
                  <UITitle level={4} style={{ textTransform: "capitalize" }} color={BaseColor}>
                    {activeDay.format("MMMM YYYY")}
                  </UITitle>
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col span={4}></Col>
                <Col span={2}></Col>
                <Col span={16} style={{ display: "flex", justifyContent: "center" }}>
                  <span
                    onClick={ isPresenceListLoading || isAbsenceListLoading ? undefined : goBack}
                    style={{ fontSize: "22px", cursor: "pointer", color: isPresenceListLoading || isAbsenceListLoading ? Colors.disabled : BaseColor }}
                  >
                    <LeftCircleOutlined />
                  </span>
                  <CustomDatePicker
                    onChange={goTo}
                    allowClear={false}
                    format={"dddd DD"}
                    value={activeDay}
                    disabledDate={disabledDate}
                  />
                  {moment(activeDay).isBefore(moment(), "day") && (
                    <span
                      onClick={ isPresenceListLoading || isAbsenceListLoading ? undefined : goNext}
                      style={{ fontSize: "22px", cursor: "pointer", color: isPresenceListLoading || isAbsenceListLoading? Colors.disabled : BaseColor }}
                    >
                      <RightCircleOutlined />
                    </span>
                  )}
                </Col>
                <Col span={2} style={{ textAlign: "right" }}></Col>
              </Row>
              <Row>
                <StyledSpan className="container_calenda">
                  <Row className="giorni">
                    <Col span={20} offset={4}>
                      <Row>
                        {timesRange.map(time => (
                          <IndexDay key={time} time={time} activeDay={activeDay} />
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  {isPresenceListLoading ? (
                    <UICard style={{ marginBottom: "20px", border: "none" }}>
                      <Skeleton active />
                    </UICard>
                  ) : (presenceList.length > 0 || absenceList.length > 0) &&
                    (!filteredGroups.length ||
                      userListComplete.filter(user =>
                        user.groups.map(g => g.id).some(g => filteredGroups.includes(g)),
                      ).length) ? (
                    <StyledCollapse
                      //@ts-ignore
                      onChange={e => setOpenedGroups(e)}
                      activeKey={openedGroups}
                      className="calenda"
                      bordered={false}
                      style={{ borderRadius: "7px" }}
                    >
                      {groups.data
                        .filter(g =>
                          !filteredGroups.length ? true : filteredGroups.includes(g.id),
                        )
                        .map((group, i) => {
                          if (
                            !!presencesOwnersUnique.filter(usr =>
                              usr.original.user.groups.map(e => e.id).includes(group.id),
                            ).length ||
                            !!absenceList.filter(abs =>
                              abs.user.groups.map(e => e.id).includes(group.id),
                            ).length
                          )
                            return (
                              <CustomPanel
                                header={
                                  <Row>
                                    <Col
                                      span={4}
                                      data-side-left
                                      style={{ borderRight: "1px solid #bcbcbc" }}
                                    >
                                      {group.name}{" "}
                                      <span
                                        className="ant-collapse-expand-icon"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        <span
                                          role={"img"}
                                          className={
                                            "anticon anticon-right ant-collapse-arrow buono"
                                          }
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="right"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            style={{
                                              //@ts-ignore
                                              transform: openedGroups.includes(group.id)
                                                ? ""
                                                : "rotate(90deg)",
                                            }}
                                          >
                                            <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </Col>
                                    <Col
                                      span={20}
                                      data-side-right
                                      onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Row data-days-cells>
                                        {timesRange.map(c => (
                                          <Col
                                            span={1}
                                            key={c}
                                            style={{
                                              background:
                                                "repeating-linear-gradient(-45deg, rgb(240 237 237), rgb(234 231 231) 2px, rgb(229, 229, 247) 3px, rgb(229, 229, 247) 4px)",
                                            }}
                                          >
                                            {"\u00A0"}
                                          </Col>
                                        ))}
                                      </Row>
                                    </Col>
                                  </Row>
                                }
                                key={group.id}
                              >
                                {presencesOwnersUnique
                                  .filter(usr =>
                                    usr.original.user.groups.map(e => e.id).includes(group.id),
                                  )
                                  .map(owner => (
                                    <Row key={owner.original.user.id}>
                                      <Col
                                        span={4}
                                        data-side-left
                                        data-user
                                        style={{
                                          borderRight: "1px solid #bcbcbc",
                                          display: "flex",
                                        }}
                                      >
                                        <Avatar
                                          src={owner.original.user.avatar}
                                          style={{
                                            marginRight: "10px",
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                        <UIText style={{ fontWeight: 600, fontSize: "14px" }}>
                                          {owner.original.user.surname} {owner.original.user.name}
                                        </UIText>
                                        {!!owner.original.user.is_single_badge && (
                                          <Tooltip
                                            title={
                                              "Quest'utente ha attivata l'opzione di Singola Timbratura"
                                            }
                                          >
                                            <IssuesCloseOutlined
                                              style={{ marginTop: "5px", marginLeft: "5px" }}
                                            />
                                          </Tooltip>
                                        )}
                                      </Col>
                                      <Col
                                        span={20}
                                        data-side-right
                                        data-user
                                        style={{ borderTop: "1px solid #bcbcbc" }}
                                      >
                                        {moment(activeDay).format("YYYY-MM-DD") ===
                                          moment().format("YYYY-MM-DD") && (
                                          <span
                                            style={{
                                              borderLeft: "1px solid black",
                                              height: "100%",
                                              position: "absolute",
                                              left:
                                                proportionTwentyfourToHundred(
                                                  `${utilityDecimalHours(
                                                    moment().hours(),
                                                    moment().minutes(),
                                                    moment().seconds(),
                                                  )}`,
                                                ) + "%",
                                            }}
                                          ></span>
                                        )}
                                        <Row data-days-cells ref={rowRef}>
                                          <>
                                            {getPresenceColsByUserId(owner.original.user.id)}
                                            {getAbsenceColsByUserId(owner.original.user.id)}
                                            {timesRange.map(c => (
                                              <Col
                                                span={1}
                                                key={c}
                                                style={{ borderRight: "1px solid #bcbcbc" }}
                                              >
                                                {"\u00A0"}
                                              </Col>
                                            ))}
                                          </>
                                        </Row>
                                      </Col>
                                    </Row>
                                  ))}
                                {absenceOwnerUniqueList
                                  .filter(
                                    usr =>
                                      usr.user.groups.map(e => e.id).includes(group.id) &&
                                      !usr.is_partial_presence &&
                                      !presenceList.find(el => el.original.user.id === usr.user.id),
                                  )
                                  .map(owner => (
                                    <Row key={owner.user.id}>
                                      <Col
                                        span={4}
                                        data-side-left
                                        data-user
                                        style={{
                                          borderRight: "1px solid #bcbcbc",
                                          display: "flex",
                                        }}
                                      >
                                        <Avatar
                                          src={owner.user.avatar}
                                          style={{
                                            marginRight: "10px",
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                        <UIText style={{ fontWeight: 600, fontSize: "14px" }}>
                                          {owner.user.surname} {owner.user.name}
                                        </UIText>
                                        {!!owner.user.is_single_badge && (
                                          <Tooltip
                                            title={
                                              "Quest'utente ha attivata l'opzione di Singola Timbratura"
                                            }
                                          >
                                            <IssuesCloseOutlined
                                              style={{ marginTop: "5px", marginLeft: "5px" }}
                                            />
                                          </Tooltip>
                                        )}
                                      </Col>
                                      <Col
                                        span={20}
                                        data-side-right
                                        data-user
                                        style={{ borderTop: "1px solid #bcbcbc" }}
                                      >
                                        {moment(activeDay).format("YYYY-MM-DD") ===
                                          moment().format("YYYY-MM-DD") && (
                                          <span
                                            style={{
                                              borderLeft: "1px solid black",
                                              height: "100%",
                                              position: "absolute",
                                              left:
                                                proportionTwentyfourToHundred(
                                                  `${utilityDecimalHours(
                                                    moment().hours(),
                                                    moment().minutes(),
                                                    moment().seconds(),
                                                  )}`,
                                                ) + "%",
                                            }}
                                          ></span>
                                        )}
                                        <Row data-days-cells ref={rowRef}>
                                          <>
                                            {getAbsenceColsByUserId(owner.user.id)}
                                            {timesRange.map(c => (
                                              <Col
                                                span={1}
                                                key={c}
                                                style={{ borderRight: "1px solid #bcbcbc" }}
                                              >
                                                {"\u00A0"}
                                              </Col>
                                            ))}
                                          </>
                                        </Row>
                                      </Col>
                                    </Row>
                                  ))}
                              </CustomPanel>
                            );
                        })}
                    </StyledCollapse>
                  ) : (
                    <Row>
                      <Col
                        style={{
                          border: "1px solid #d9d9d9",
                          borderRadius: "7px",
                          padding: "50px",
                          textAlign: "center",
                        }}
                        span={24}
                      >
                        <UITitle level={4}>
                          Nessuna presenza disponibile per i filtri selezionati
                        </UITitle>
                      </Col>
                    </Row>
                  )}
                </StyledSpan>
              </Row>
            </Col>
          </Row>
          {!!activePresence && (
            <ShowPresenceDrawer
              visible={showModal}
              modalLoading={false}
              onClose={handleCloseModal}
              presence={activePresence}
            />
          )}
          {!!activeRequest && (
            <RequestDetailDrawer
              visible={showModal}
              modalLoading={modalLoading}
              request={activeRequest}
              request_id={activeRequest.id}
              currentRange={datesRange}
              onClose={handleCloseModalRequest}
              editMode={false}
            />
          )}
        </>
      )}
      {selectedMode === "Modifica" && (
        <EditPresenceCalendar
          activeDay={activeDay}
          updateAbsenceList={updateAbsenceList}
          goTo={goTo}
          goNext={goNext}
          goBack={goBack}
          timesRange={timesRange}
          isPresenceListLoading={isPresenceListLoading}
          isAbsenceListLoading={isAbsenceListLoading}
          openedGroups={openedGroups}
          presenceList={presenceList}
          groups={groups}
          presencesOwnersUnique={presencesOwnersUnique}
          filteredGroups={filteredGroups}
          absenceList={absenceList}
          absenceOwnerUniqueList={absenceOwnerUniqueList}
          setOpenedGroups={setOpenedGroups}
          updatePresenceList={updatePresenceList}
          updateRequestList={checkForUpdateRequestList}
          setInitialValues={setInitialValues}
        />
      )}
    </>
  );
};

const StyledSpan = styled.span`
  overflow: scroll;
  width: 100%;
  padding-bottom: 15px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar {
    height: 10px;
  }
  .calenda {
    min-width: 1105px;
  }
  .giorni {
    min-width: 1105px;
    margin-bottom: 10px;
  }
  .giorni .ant-cascader .ant-select-selector {
    border-radius: 7px;
  }

  .pipallino {
    text-align: left !important;
  }
  .pipallino::last-of-type {
  }

  .pipallino span {
    position: relative;
    left: -10px;
  }
`;

// mettere in file singolo
const StyledCollapse = styled(Collapse)`
  border-radius: 7px;
  position: relative !important;

  .ant-collapse-header {
    display: block !important;
  }
  .ant-collapse:first-child {
    border-radius: 7px;
  }
  .ant-collapse-item:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .ant-collapse-item:last-of-type [data-side-left]:not([data-user]) {
    border-bottom-left-radius: 7px;
  }

  .ant-collapse-item:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .ant-collapse-item:first-of-type [data-side-right]:not([data-user]) [data-days-cells] {
    border-top: 1px solid #bcbcbc;
    border-top-right-radius: 7px;
  }

  .ant-collapse-item:first-of-type
    [data-side-right]:not([data-user])
    [data-days-cells]
    .ant-col:last-of-type {
    border-top-right-radius: 7px;
  }

  .ant-collapse-item:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse .ant-col-1:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:first-of-type [data-side-left]:not([data-user]) {
    border-top-left-radius: 7px;
  }
  .ant-collapse-item:last-of-type .ant-row:last-child [data-side-left][data-user] {
    border-bottom-left-radius: 7px;
  }
  .ant-collapse-item:last-of-type .ant-row:last-child .ant-col:last-of-type {
    border-bottom-left-radius: 7px;
  }
  .ant-collapse-header[aria-expanded="true"] [data-side-left] {
    border-bottom-left-radius: 0px !important;
  }
`;
// mettere in file singolo
const CustomPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0px !important;
  }

  .ant-row[data-days-cells] {
    height: 100% !important;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-arrow:not(.buono) {
    display: none !important;
  }

  [data-side-left] {
    padding: 12px 10px;
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;
  }
  [data-side-left][data-user] {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}) !important;
  }
  [data-side-right]:not([data-user]) {
    //pointer-events: none !important;
    cursor: default;
    touch-action: none;
  }
  [data-days-cells] {
    box-shadow: inset 7px 0 9px -7px rgb(124 124 124 / 40%),
      inset -7px 0 9px -7px rgb(124 124 124 / 40%);
  }
  .ant-col .request-cell {
    border: 0px !important;
    border-right: 1px solid #bcbcbc;

    background-size: 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-transform: capitalize;

    height: 70%;
    margin-top: 7px;
  }
  .request-cell + .ant-col:not(.request-cell) {
    //border-left: 1px solid #bcbcbc;
    box-shadow: -1px 0px 0px 0px #bcbcbc;
  }
`;

// mettere in file singolo
const CustomDatePicker = styled(DatePicker)`
  border-radius: 7px;
  margin: 0px 15px;
  input {
    text-transform: capitalize;
  }
`;

const CustomCascader = styled(Cascader)`
  .ant-select-selector {
    border-radius: 7px !important;
  }
`;
