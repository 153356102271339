/** @format */

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Collapse, DatePicker, Row, Skeleton, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { goBack } from "connected-react-router";
import moment from "moment";
import React, { Ref, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { TGroup, TGroupList } from "../../../../redux-modules/declarations/maps/groups";
import {
  TAbsence,
  TCustomPresence,
  TModifiedPresence,
  TPresence,
} from "../../../../redux-modules/declarations/maps/presences";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { selectorCustomStyleOptions } from "../../../../redux-modules/selectors/selectorsUI";
import { BaseColor, Colors } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { utilityDecimalHours } from "../../../../utils/format/decimalHours";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { hexToRgb } from "../../../../utils/styles";
import { UICard } from "../../../ui/Card";
import { UICheckbox } from "../../../ui/Checkbox";
import { UIText } from "../../../ui/Text";
import { UITitle } from "../../../ui/Title";
import { CreatePresenceDrawer, TPresenceCreateObject } from "../../SideDialogs/AddPresence";
import { EditPresenceDrawer } from "../../SideDialogs/EditPresence";
import { ShowPresenceDrawer } from "../../SideDialogs/ShowPresence";
import { IndexDay } from "../partials/IndexDay";
import { TRequest } from "../../../../redux-modules/declarations/maps/requests";
import { RequestDetailDrawer } from "../../RequestCalendar/partials/RequestDetailDrawer";
import { utilityGetDatesSiblingsToOneDate } from "../../../../utils/format/rangeBetweenTwoDates";
import { useRequests } from "../../../../redux-modules/hooks/useRequests";
const { Panel } = Collapse;

interface Props {
  activeDay: moment.Moment;
  goTo: (date: moment.Moment) => void;
  goNext: () => void;
  goBack: () => void;
  timesRange: string[];
  isPresenceListLoading: boolean;
  isAbsenceListLoading: boolean;
  presenceList: TCustomPresence[];
  openedGroups: string[];
  presencesOwnersUnique: TCustomPresence[];
  groups: TGroupList;
  filteredGroups: string[];
  absenceList: TAbsence[];
  setOpenedGroups: React.Dispatch<React.SetStateAction<never[]>>;
  updatePresenceList: (date: string) => void;
  setInitialValues: (obj: TPresenceCreateObject) => void;
  updateAbsenceList: () => void;
  updateRequestList: () => void;
  absenceOwnerUniqueList: TAbsence[];
}

export const EditPresenceCalendar = ({
  activeDay,
  goTo,
  goNext,
  goBack,
  timesRange,
  isPresenceListLoading,
  isAbsenceListLoading,
  presenceList,
  openedGroups,
  presencesOwnersUnique,
  groups,
  filteredGroups,
  absenceList,
  setOpenedGroups,
  updatePresenceList,
  setInitialValues,
  updateAbsenceList,
  updateRequestList,
  absenceOwnerUniqueList,
}: Props): JSX.Element => {
  const rowRef: Ref<HTMLDivElement> | undefined = useRef(null);
  const [activePresence, setActivePresence] = useState<TCustomPresence | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [filterAnomalies, setFilterAnomalies] = useState(false);
  const permissionList = useSelector(selectorPermissionList);
  const canViewBancaOre = hasPermission(permissionList, "Presence", "Canshowbancaore");
  const [filteredPresences, setFilteredPresences] = useState<TCustomPresence[]>(
    presencesOwnersUnique,
  );
  const [filteredAbsences, setFilteredAbsences] = useState<TAbsence[]>(absenceOwnerUniqueList);
  const customStyleOptions = useSelector(selectorCustomStyleOptions);

  const [datesRange] = useState(utilityGetDatesSiblingsToOneDate(moment().toDate()));
  const {requestList} = useRequests(datesRange);
  const [activeRequest, setActiveRequest] = useState<TRequest | null>(null);
    
  const getDuration = (presence: TCustomPresence) => {
    let duration = moment.duration(
      moment(presence.original.datetime_to, "DD/MM/YYYY HH:mm:ss").diff(
        moment(presence.original.datetime_from, "DD/MM/YYYY HH:mm:ss"),
      ),
    );
    if (!!presence.modified) {
      duration = moment.duration(
        moment(presence.modified.presence_datetime_to, "DD/MM/YYYY HH:mm:ss").diff(
          moment(presence.modified.presence_datetime_from, "DD/MM/YYYY HH:mm:ss"),
        ),
      );
    }
    return duration.asHours();
  };

  const handleOpenModalRequest = (id: string) => {
    const request = requestList.find(el => el.id === id);
    if (request) {
      setShowModal(true);
      setModalLoading(true);
      apiClient
        .get(API_ENDPOINTS.REQUESTS + `/${request.id}`)
        .then(res => {
          setActiveRequest(res.data.data);
          setModalLoading(false);
        })
        .catch(err => {});
    }
  };

  const handleCloseModalRequest = (refresh: boolean = false) => {
    setShowModal(false);
    setActiveRequest(null);
    if(refresh) {
      updatePresenceList(activeDay.format("YYYY-MM-DD"));
      updateRequestList();
      updateAbsenceList();
    }
  };

  const handleOpenModal = (presence_id: string, modified: TModifiedPresence) => {
    //const presence = getPresenceByIndex(index, user);
    if (presence_id) {
      setShowModal(true);
      setModalLoading(true);
      apiClient
        .get(API_ENDPOINTS.PRESENCES + `/${presence_id}`)
        .then(res => {
          setActivePresence(res.data.data);
          setModalLoading(false);
        })
        .catch(err => {});
    }
  };

  const handleCloseModal = (refresh: boolean = false) => {
    setShowModal(false);
    setActivePresence(null);
    if (refresh) updatePresenceList(activeDay.format("YYYY-MM-DD"));
  };

  const proportionTwentyfourToHundred = (hour: string) => {
    const hourProportion = parseFloat(hour) / 24;
    return (hourProportion * 100).toFixed(2);
  };

  const onChangeFilterAnomalies = (e: CheckboxChangeEvent) => {
    setFilterAnomalies(e.target.checked);
  };

  const sendInitialValuesFromAbsence = (absence: TAbsence) => {
    const obj: TPresenceCreateObject = {
      user_id: absence.user.id,
      datetime_from: moment(absence.datetime_from),
      datetime_to: moment(absence.datetime_to),
      shift_type_id: absence.shift_type_id ?? undefined,
    };
    setInitialValues(obj);
  };

  const getAbsenceColsByUserId = (userId: string) => {
    const todaysAbsences = absenceList.filter(
      absence => absence.user.id === userId && !absence.is_partial_presence,
    );

    return (
      <>
        {todaysAbsences.map(absence => (
          <div
            key={absence.datetime_from}
            onClick={() => absence.id ? handleOpenModalRequest(absence.id) : sendInitialValuesFromAbsence(absence)} 
            style={{
              position: "absolute",
              cursor: "pointer",
              backgroundColor: absence.color + "cc",
              left: proportionTwentyfourToHundred(absence.centesimal_datetime_from) + "%",
              width: proportionTwentyfourToHundred(absence.centesimal_duration) + "%",
              //@ts-ignore
              zIndex: !!absence.size_unit ? (absence.size_unit == "GIORNI" ? 990 : 1000) : 992,
              display: "flex",
              justifyContent: "space-between",
              padding: "1px 5px",
              fontWeight: 500,
              marginTop: "9px",
              borderRadius: "7px",
              color: "white",
              border: "1px solid " + absence.color,
              textShadow: "0px 0px 2px black",
            }}
          >
            <span style={{ marginRight: "5px" }}>{absence.description}</span>
          </div>
        ))}
      </>
    );
  };

  const getPresenceColsByUserId = (userId: string): JSX.Element => {
    const todaysPresences = presenceList.filter(el => el.original.user_id === userId);
    const todaysAbsences = absenceList.filter(
      el => el.user.id === userId && el.is_partial_presence,
    );

    return (
      <>
        {todaysAbsences.map(absence => (
          <div
            key={absence.datetime_from}
            onClick={() => sendInitialValuesFromAbsence(absence)}
            style={{
              position: "absolute",
              cursor: "pointer",
              backgroundColor: absence.color + "cc",
              left: proportionTwentyfourToHundred(absence.centesimal_datetime_from) + "%",
              width: proportionTwentyfourToHundred(absence.centesimal_duration) + "%",
              //@ts-ignore
              zIndex: !!absence.size_unit ? (absence.size_unit == "GIORNI" ? 990 : 994) : 992,
              display: "flex",
              justifyContent: "space-between",
              padding: "1px 5px",
              fontWeight: 500,
              marginTop: "9px",
              borderRadius: "7px",
              color: "white",
              border: "1px solid " + absence.color,
              textShadow: "0px 0px 2px black",
            }}
          >
            <span style={{ marginRight: "5px" }}>{absence.description}</span>
          </div>
        ))}
        {todaysPresences.map(presence => {
          if (!!presence.modified)
            return (
              <>
                <div
                  key={presence.modified.id}
                  style={{
                    position: "absolute",
                    background:
                      "repeating-linear-gradient(224deg, rgb(237 237 237), rgb(230 230 230) 2px, rgb(213 213 229) 3px, rgb(212 212 212) 4px)",
                    left:
                      proportionTwentyfourToHundred(
                        presence.modified.is_notturno == "1" && activeDay.format("YYYY-MM-DD") == moment(presence.modified.shift_planning_datetime_to).format("YYYY-MM-DD") ? "0" :
                        moment
                          .duration(
                            moment(presence.modified.shift_planning_datetime_from).format("HH:mm"),
                          )
                          .asHours()
                          .toString(),
                      ) + "%",
                    width:
                      proportionTwentyfourToHundred(
                        presence.modified.is_notturno == "1" && activeDay.format("YYYY-MM-DD") == moment(presence.modified.shift_planning_datetime_to).format("YYYY-MM-DD") ? 
                        (parseInt(presence.modified.shift_planning_centesimal_duration) - parseInt(presence.modified.ui_centesimal_duration)).toString() :
                        (
                          presence.modified.shift_planning_centesimal_duration
                          ? presence.modified.shift_planning_centesimal_duration
                          : presence.modified.shift_planning_duration
                        ),
                      ) + "%",
                    zIndex: 993,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1px 5px",
                    fontWeight: 500,
                    marginTop: "9px",
                    borderRadius: "7px",
                    color: "white",
                    border: "1px solid #8080802b",
                    textShadow: "0px 0px 2px black",
                    opacity: 0.8,
                  }}
                >
                  {"\u00A0"}
                </div>
                <div
                  onClick={() => handleOpenModal(presence.original.id, presence.modified)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    backgroundColor:
                      getDuration(presence) > 13
                        ? "red"
                        : presence?.original.presence_type_color + "cc",
                    left:
                      proportionTwentyfourToHundred(
                        presence.modified
                          ? presence.modified.ui_centesimal_datetime_from
                          : presence.original.ui_centesimal_datetime_from,
                      ) + "%",
                    width:
                      proportionTwentyfourToHundred(
                        presence.modified &&
                          parseInt(presence.modified.ui_centesimal_duration) <= 24
                          ? presence.modified.ui_centesimal_duration
                          : presence.original.ui_centesimal_duration,
                      ) + "%",
                    zIndex: 999,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1px 5px",
                    fontWeight: 500,
                    marginTop: "9px",
                    borderRadius: "7px",
                    color: "white",
                    border: "1px solid " + presence?.original.presence_type_color,
                    textShadow: "0px 0px 2px black",
                  }}
                >
                  <span
                    style={{
                      marginRight: "5px",
                      textShadow:
                        customStyleOptions?.showHandonLabelInPresenceList &&
                        !!presence.modified &&
                        presence.modified.presence_datetime_from != presence.original.datetime_from
                          ? "2px 0 red, -2px 0 red, 0 2px red, 0 -2px red, 1px 1px red, -1px -1px red, 1px -1px red, -1px 1px red"
                          : "0px 0px 2px black",
                    }}
                  >
                    {moment(
                      presence.modified
                        ? presence.modified.presence_datetime_from
                        : presence.original.datetime_from,
                      "DD/MM/YYYY HH:mm:ss",
                    ).format("HH:mm")}
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    {customStyleOptions?.showOfficeLabelInPresenceList && (
                      <span
                        style={{
                          fontSize: "12px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          marginTop: "2px",
                        }}
                      >
                        {presence.original.office?.name}
                      </span>
                    )}
                    {!presence.modified.is_timebank_approved && (
                      <ExclamationCircleOutlined
                        style={{ filter: "drop-shadow(red 0px 0px 2px)" }}
                      />
                    )}
                  </span>
                  <span
                    style={{
                      textShadow:
                        customStyleOptions?.showHandonLabelInPresenceList &&
                        !!presence.modified &&
                        !!presence.original.datetime_to &&
                        presence.modified.presence_datetime_to != presence.original.datetime_to
                          ? "2px 0 red, -2px 0 red, 0 2px red, 0 -2px red, 1px 1px red, -1px -1px red, 1px -1px red, -1px 1px red"
                          : "0px 0px 2px black",
                    }}
                  >
                    {presence.original.datetime_to ? (
                      moment(
                        presence.modified
                          ? presence.modified.presence_datetime_to
                          : presence.original.datetime_to,
                        "DD/MM/YYYY HH:mm:ss",
                      ).format("HH:mm")
                    ) : (
                      <SyncOutlined spin style={{ filter: "drop-shadow(black 0px 0px 2px)" }} />
                    )}
                  </span>
                </div>
              </>
            );
          return (
            <div
              /*
              onClick={
                presence.original.user.is_single_badge
                  ? undefined
                  : () => handleOpenModal(presence.original.id, presence.modified)
              }*/
              onClick={() => handleOpenModal(presence.original.id, presence.modified)}
              key={presence.original.id}
              style={{
                position: "absolute",
                cursor: "pointer",
                backgroundColor: presence.original.user.is_single_badge
                  ? presence?.original.presence_type_color + "cc"
                  : "red",
                left:
                  proportionTwentyfourToHundred(presence.original.ui_centesimal_datetime_from) +
                  "%",
                width:
                  proportionTwentyfourToHundred(presence.original.ui_centesimal_duration) + "%",
                zIndex: 999,
                display: "flex",
                justifyContent: "space-between",
                padding: "1px 5px",
                fontWeight: 500,
                marginTop: "9px",
                borderRadius: "7px",
                color: "white",
                border: "1px solid " + presence?.original.presence_type_color,
                textShadow: "0px 0px 2px black",
              }}
            >
              <span style={{ marginRight: "5px" }}>
                {moment(presence.original.datetime_from, "DD/MM/YYYY HH:mm:ss").format("HH:mm")}
              </span>
              <span>
                {presence.original.datetime_to ? (
                  moment(presence.original.datetime_to, "DD/MM/YYYY HH:mm:ss").format("HH:mm")
                ) : (
                  <SyncOutlined spin style={{ filter: "drop-shadow(black 0px 0px 2px)" }} />
                )}
              </span>
            </div>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (filterAnomalies) {
      setFilteredPresences(
        presencesOwnersUnique
          .map(usr => ({
            ...usr,
            has_anomalia: presenceList.some(
              pres =>
                pres.original.user_id === usr.original.user_id &&
                !!pres.modified &&
                !pres.modified.is_timebank_approved,
            ),
          }))
          .filter(el => (!el.modified && !el.original?.user?.is_single_badge) || !!el.has_anomalia),
      );
      setFilteredAbsences(
        absenceOwnerUniqueList.filter(el => el.description === "Mancata Timbratura"),
      );
    } else {
      setFilteredPresences(presencesOwnersUnique);
      setFilteredAbsences(absenceOwnerUniqueList);
    }
  }, [filterAnomalies, presenceList]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={6}></Col>
            <Col span={16} style={{ textAlign: "center" }}>
              <UITitle level={4} style={{ textTransform: "capitalize" }} color={BaseColor}>
                {activeDay.format("MMMM YYYY")}
              </UITitle>
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={4}></Col>
            <Col span={2}></Col>
            <Col span={16} style={{ display: "flex", justifyContent: "center" }}>
              <span
                onClick={ isPresenceListLoading || isAbsenceListLoading ? undefined : goBack}
                style={{ fontSize: "22px", cursor: "pointer", color: isPresenceListLoading || isAbsenceListLoading ? Colors.disabled : BaseColor }}
              >
                <LeftCircleOutlined />
              </span>
              <CustomDatePicker
                // @ts-ignore
                onChange={goTo}
                allowClear={false}
                format={"dddd DD"}
                value={activeDay}
              />
              {moment(activeDay).isBefore(moment(), "day") && (
                <span
                  onClick={ isPresenceListLoading || isAbsenceListLoading ? undefined : goNext}
                  style={{ fontSize: "22px", cursor: "pointer", color: isPresenceListLoading || isAbsenceListLoading ? Colors.disabled : BaseColor }}
                > 
                  <RightCircleOutlined />
                </span>
              )}
            </Col>
            <Col span={2} style={{ textAlign: "right" }}></Col>
          </Row>
          <Row>
            <StyledSpan className="container_calenda">
              <Row className="giorni">
                <Col span={4}>
                  <UICheckbox onChange={onChangeFilterAnomalies}>Filtra anomalie</UICheckbox>
                </Col>
                <Col span={20}>
                  <Row>
                    {timesRange.map(time => (
                      <IndexDay key={time} time={time} activeDay={activeDay} />
                    ))}
                  </Row>
                </Col>
              </Row>
              {isPresenceListLoading ? (
                <UICard style={{ marginBottom: "20px", border: "none" }}>
                  <Skeleton active />
                </UICard>
              ) : presenceList.length > 0 || absenceList.length > 0 ? (
                <StyledCollapse
                  //@ts-ignore
                  onChange={e => setOpenedGroups(e)}
                  activeKey={openedGroups}
                  className="calenda"
                  bordered={false}
                  style={{ borderRadius: "7px" }}
                >
                  {groups.data
                    .filter(g => (!filteredGroups.length ? true : filteredGroups.includes(g.id)))
                    .map((group, i) => {
                      if (
                        !!filteredPresences.filter(usr =>
                          usr.original.user.groups.map(e => e.id).includes(group.id),
                        ).length ||
                        !!absenceList.filter(abs =>
                          abs.user.groups.map(e => e.id).includes(group.id),
                        ).length
                      )
                        return (
                          <CustomPanel
                            header={
                              <Row>
                                <Col
                                  span={4}
                                  data-side-left
                                  style={{ borderRight: "1px solid #bcbcbc" }}
                                >
                                  {group.name}{" "}
                                  <span
                                    className="ant-collapse-expand-icon"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <span
                                      role={"img"}
                                      className={"anticon anticon-right ant-collapse-arrow buono"}
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="right"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        style={{
                                          //@ts-ignore
                                          transform: openedGroups.includes(group.id)
                                            ? ""
                                            : "rotate(90deg)",
                                        }}
                                      >
                                        <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                      </svg>
                                    </span>
                                  </span>
                                </Col>
                                <Col
                                  span={20}
                                  data-side-right
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <Row data-days-cells>
                                    {timesRange.map(c => (
                                      <Col
                                        span={1}
                                        key={c}
                                        style={{
                                          background:
                                            "repeating-linear-gradient(-45deg, rgb(240 237 237), rgb(234 231 231) 2px, rgb(229, 229, 247) 3px, rgb(229, 229, 247) 4px)",
                                        }}
                                      >
                                        {"\u00A0"}
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                            }
                            key={group.id}
                          >
                            {filteredPresences
                              .filter(usr =>
                                usr.original.user.groups.map(e => e.id).includes(group.id),
                              )
                              .map(owner => (
                                <Row key={owner.original.user.id}>
                                  <Col
                                    span={4}
                                    data-side-left
                                    data-user
                                    style={{
                                      borderRight: "1px solid #bcbcbc",
                                      display: "flex",
                                    }}
                                  >
                                    <Avatar
                                      src={owner.original.user.avatar}
                                      style={{
                                        marginRight: "10px",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <UIText style={{ fontWeight: 600, fontSize: "14px" }}>
                                      {owner.original.user.surname} {owner.original.user.name}
                                    </UIText>
                                    {!!owner.original.user.is_single_badge && (
                                      <Tooltip
                                        title={
                                          "Quest'utente ha attivata l'opzione di Singola Timbratura"
                                        }
                                      >
                                        <IssuesCloseOutlined
                                          style={{ marginTop: "5px", marginLeft: "5px" }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Col>
                                  <Col
                                    span={20}
                                    data-side-right
                                    data-user
                                    style={{ borderTop: "1px solid #bcbcbc" }}
                                  >
                                    {moment(activeDay).format("YYYY-MM-DD") ===
                                      moment().format("YYYY-MM-DD") && (
                                      <span
                                        style={{
                                          borderLeft: "1px solid black",
                                          height: "100%",
                                          position: "absolute",
                                          left:
                                            proportionTwentyfourToHundred(
                                              `${utilityDecimalHours(
                                                moment().hours(),
                                                moment().minutes(),
                                                moment().seconds(),
                                              )}`,
                                            ) + "%",
                                        }}
                                      ></span>
                                    )}
                                    <Row data-days-cells ref={rowRef}>
                                      <>
                                        {getPresenceColsByUserId(owner.original.user.id)}
                                        {getAbsenceColsByUserId(owner.original.user.id)}
                                        {timesRange.map(c => (
                                          <Col
                                            span={1}
                                            key={c}
                                            style={{ borderRight: "1px solid #bcbcbc" }}
                                          >
                                            {"\u00A0"}
                                          </Col>
                                        ))}
                                      </>
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                            {filteredAbsences
                              .filter(
                                usr =>
                                  usr.user.groups.map(e => e.id).includes(group.id) &&
                                  !usr.is_partial_presence &&
                                  !filteredPresences.find(
                                    el => el.original.user.id === usr.user.id,
                                  ),
                              )
                              .map(owner => (
                                <Row key={owner.user.id}>
                                  <Col
                                    span={4}
                                    data-side-left
                                    data-user
                                    style={{
                                      borderRight: "1px solid #bcbcbc",
                                      display: "flex",
                                    }}
                                  >
                                    <Avatar
                                      src={owner.user.avatar}
                                      style={{
                                        marginRight: "10px",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <UIText style={{ fontWeight: 600, fontSize: "14px" }}>
                                      {owner.user.surname} {owner.user.name}
                                    </UIText>
                                    {!!owner.user.is_single_badge && (
                                      <Tooltip
                                        title={
                                          "Quest'utente ha attivata l'opzione di Singola Timbratura"
                                        }
                                      >
                                        <IssuesCloseOutlined
                                          style={{ marginTop: "5px", marginLeft: "5px" }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Col>
                                  <Col
                                    span={20}
                                    data-side-right
                                    data-user
                                    style={{ borderTop: "1px solid #bcbcbc" }}
                                  >
                                    {moment(activeDay).format("YYYY-MM-DD") ===
                                      moment().format("YYYY-MM-DD") && (
                                      <span
                                        style={{
                                          borderLeft: "1px solid black",
                                          height: "100%",
                                          position: "absolute",
                                          left:
                                            proportionTwentyfourToHundred(
                                              `${utilityDecimalHours(
                                                moment().hours(),
                                                moment().minutes(),
                                                moment().seconds(),
                                              )}`,
                                            ) + "%",
                                        }}
                                      ></span>
                                    )}
                                    <Row data-days-cells ref={rowRef}>
                                      <>
                                        {getAbsenceColsByUserId(owner.user.id)}
                                        {timesRange.map(c => (
                                          <Col
                                            span={1}
                                            key={c}
                                            style={{ borderRight: "1px solid #bcbcbc" }}
                                          >
                                            {"\u00A0"}
                                          </Col>
                                        ))}
                                      </>
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                          </CustomPanel>
                        );
                    })}
                </StyledCollapse>
              ) : (
                <Row>
                  <Col
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: "7px",
                      padding: "50px",
                      textAlign: "center",
                    }}
                    span={24}
                  >
                    <UITitle level={4}>Nessuna presenza disponibile</UITitle>
                  </Col>
                </Row>
              )}
            </StyledSpan>
          </Row>
        </Col>
      </Row>
      {!!activePresence && (
        <EditPresenceDrawer
          currentDate={activeDay}
          visible={showModal}
          modalLoading={false}
          onClose={handleCloseModal}
          presence={activePresence}
          updateAbsenceList={updateAbsenceList}
          updateRequestList={updateRequestList}
        />
      )}
      {!!activeRequest && (
        <RequestDetailDrawer
          visible={showModal}
          modalLoading={modalLoading}
          onClose={handleCloseModalRequest}
          request={activeRequest}
          request_id={activeRequest.id}
          currentRange={datesRange}
        />
      )}
    </>
  );
};

const StyledSpan = styled.span`
  overflow: scroll;
  width: 100%;
  padding-bottom: 15px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar {
    height: 10px;
  }
  .calenda {
    min-width: 1105px;
  }
  .giorni {
    min-width: 1105px;
    margin-bottom: 10px;
  }
  .giorni .ant-cascader .ant-select-selector {
    border-radius: 7px;
  }

  .pipallino {
    text-align: left !important;
  }
  .pipallino::last-of-type {
  }

  .pipallino span {
    position: relative;
    left: -10px;
  }
`;

// mettere in file singolo
const StyledCollapse = styled(Collapse)`
  border-radius: 7px;
  position: relative !important;

  .ant-collapse-header {
    display: block !important;
  }
  .ant-collapse:first-child {
    border-radius: 7px;
  }
  .ant-collapse-item:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .ant-collapse-item:last-of-type [data-side-left]:not([data-user]) {
    border-bottom-left-radius: 7px;
  }

  .ant-collapse-item:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .ant-collapse-item:first-of-type [data-side-right]:not([data-user]) [data-days-cells] {
    border-top: 1px solid #bcbcbc;
    border-top-right-radius: 7px;
  }

  .ant-collapse-item:first-of-type
    [data-side-right]:not([data-user])
    [data-days-cells]
    .ant-col:last-of-type {
    border-top-right-radius: 7px;
  }

  .ant-collapse-item:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse .ant-col-1:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:first-of-type [data-side-left]:not([data-user]) {
    border-top-left-radius: 7px;
  }
  .ant-collapse-item:last-of-type .ant-row:last-child [data-side-left][data-user] {
    border-bottom-left-radius: 7px;
  }
  .ant-collapse-item:last-of-type .ant-row:last-child .ant-col:last-of-type {
    border-bottom-left-radius: 7px;
  }
  .ant-collapse-header[aria-expanded="true"] [data-side-left] {
    border-bottom-left-radius: 0px !important;
  }
`;
// mettere in file singolo
const CustomPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0px !important;
  }

  .ant-row[data-days-cells] {
    height: 100% !important;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-arrow:not(.buono) {
    display: none !important;
  }

  [data-side-left] {
    padding: 12px 10px;
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;
  }
  [data-side-left][data-user] {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}) !important;
  }
  [data-side-right]:not([data-user]) {
    //pointer-events: none !important;
    cursor: default;
    touch-action: none;
  }
  [data-days-cells] {
    box-shadow: inset 7px 0 9px -7px rgb(124 124 124 / 40%),
      inset -7px 0 9px -7px rgb(124 124 124 / 40%);
  }
  .ant-col .request-cell {
    border: 0px !important;
    border-right: 1px solid #bcbcbc;

    background-size: 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-transform: capitalize;

    height: 70%;
    margin-top: 7px;
  }
  .request-cell + .ant-col:not(.request-cell) {
    //border-left: 1px solid #bcbcbc;
    box-shadow: -1px 0px 0px 0px #bcbcbc;
  }
`;

// mettere in file singolo
const CustomDatePicker = styled(DatePicker)`
  border-radius: 7px;
  margin: 0px 15px;
  input {
    text-transform: capitalize;
  }
`;
